<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>小程序设置</span>
      </div>

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="小程序配置" name="first">
          <el-form style="width: 50%" :model="form1" ref="form1" label-width="180px">
            <el-form-item label="是否使用第三方授权" prop="name">
              <el-radio-group>
                <el-radio label="否"></el-radio>
                <el-radio label="是"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item   label="小程序AppId">
              <el-input v-model="form1.mpwx_appid" autocomplete="off"></el-input>
            </el-form-item>

             <el-form-item label="小程序appSecret">
              <el-input v-model="form1.mpwx_app_secret" autocomplete="off"></el-input>
            </el-form-item>

             <el-form-item label="Token(令牌)">
              <el-input v-model="form1.mpwx_app_token" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit1">保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="支付配置" name="second">
          <el-form style="width: 50%" :model="form2" ref="form2" label-width="180px">
            <el-form-item label="支付类型选择" prop="name">
              <el-radio-group v-model="form2.pay_wx_merchant_type">
                <el-radio label="1">普通商户</el-radio>
                <el-radio label="2">服务商</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="微信支付商户号" prop="description">
              <el-input v-model="form2.pay_wx_merchant_id" autocomplete="off"></el-input>
            </el-form-item>

            <el-form-item label="微信支付Api密钥">
              <el-input v-model="form2.pay_wx_key" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="微信支付apiclient_cert.pem">
              <el-input
                type="textarea"
                :rows="5"
                v-model="form2.pay_wx_apiclient_cert"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="微信支付apiclient_key.pem">
              <el-input
                type="textarea"
                :rows="5"
                v-model="form2.pay_wx_apiclient_key"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit2">保存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "first",
      total: 0,
      rule: [],
      filterData: { page: 1 },
      tableData: [],
      tableData1: [],
      dialogFormVisible: false,
      form1: {},
      form2: {},
      product_id: 0,
      products: [],
    };
  },

  created() {
    this.query1();
    this.query2();
  },

  methods: {
    query1() {
      this.$http
        .post("/admin/config/get", ["mpwx_appid", "mpwx_app_secret","mpwx_app_token"])
        .then((res) => {
          this.form1 = res.data;
        })
        .catch(() => {});
    },

    query2() {
      this.$http
        .post("/admin/config/get", ["pay_wx_merchant_type", "pay_wx_merchant_id","pay_wx_key","pay_wx_apiclient_cert","pay_wx_apiclient_key"])
        .then((res) => {
          this.form2 = res.data;
        })
        .catch(() => {});
    },

    submit1() {
      this.$refs.form1.validate((valid) => {
        if (valid) {
          this.$http.post("/admin/config/save", this.form1).then(() => {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    submit2() {
      this.$refs.form2.validate((valid) => {
        if (valid) {
          this.$http.post("/admin/config/save", this.form2).then(() => {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped></style>
